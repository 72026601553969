import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import AuthService from '../../services/auth';
import * as moment from 'moment';
import * as _ from 'underscore';
class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user: {},
            orders: [],
            view: 'account-info'
        }
        this.checkUser()
    }

    async checkUser() {
        const user = await AuthService.getUser();
        if (user !== this.state.user) {
            this.setState({ user: user.user }, () => this.getUserOrders());
        }
    }

    async getUserOrders() {
        const { user } = this.state
        const orders = await AuthService.getOrders(user);
        if (orders !== this.state.orders) {
            this.setState({ orders: orders.orders.data.reverse() })
        }
    }

    logout = (e) => {
        e.preventDefault()
        AuthService.signOut()
            .then(result => this.props.history.push('/'))
            .catch(err => console.log(err))
    }
    goToOrder = (e, order) => {
        e.preventDefault()
        this.props.history.push({
            pathname: '/order-history',
            state: { order }
        })
    }

    renderSwitch = (view) => {
        const { user, orders } = this.state
        switch (view) {
            case 'account-info':
                return (
                    <div className="col-lg-9">
                        <div className="dashboard-right">
                            <div className="dashboard">
                                <div className="page-title">
                                    <h2>My Dashboard</h2>
                                </div>
                                <div className="welcome-msg">
                                    <p>Hello, {user.fname + ' ' + user.lname} !</p>
                                    <p>From your My Account Dashboard you have the ability to view a snapshot of
                                        your recent account activity and update your account information. Select
                                                a link below to view or edit information.</p>
                                </div>
                                <div className="box-account box-info">
                                    <div className="box-head">
                                        <h2>Account Information</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="box">
                                                <div className="box-title">
                                                    <h3>Contact Information</h3>
                                                    <a href="#">Edit</a>
                                                </div>
                                                <div className="box-content">
                                                    <h6>{user.fname + ' ' + user.lname}</h6>
                                                    <h6>{user.email}</h6>
                                                    <h6><a href="#">Change Password</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="box">
                                                <div className="box-title">
                                                    <h3>Newsletters</h3>
                                                    <a href="#">Edit</a>
                                                </div>
                                                <div className="box-content">
                                                    <p>
                                                        You are currently not subscribed to any newsletter.
                                                            </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="box">
                                            <div className="box-title">
                                                <h3>Address Book</h3>
                                                <a href="#">Manage Addresses</a>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h6>Default Billing Address</h6>
                                                    <address>
                                                        You have not set a default billing address.<br />
                                                        <a href="#">Edit Address</a>
                                                    </address>
                                                </div>
                                                <div className="col-sm-6">
                                                    <h6>Default Shipping Address</h6>
                                                    <address>
                                                        You have not set a default shipping address.<br />
                                                        <a href="#">Edit Address</a>
                                                    </address>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'my-orders':
                return (
                    <div className="col-lg-9">
                        <div className="dashboard-right">
                            <div className="dashboard">
                                <div className="page-title">
                                    <h2>My Orders</h2>
                                </div>

                                <div className="box-account box-info">
                                    <div className="box-head">
                                        {/* <h2>Order Information</h2> */}
                                    </div>
                                </div>
                                <div className="order-container">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Order Number</th>
                                                <th>Order Date</th>
                                                <th>Amount</th>
                                                {/* <th>Order</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.map((order) => {
                                                return (
                                                    <tr key={order.number}>
                                                        <td><a onClick={(e) => {this.goToOrder(e, order)}}>{order.number}</a></td>
                                                        <td>{moment(order.date._d).format('DD/MM/YYYY')}</td>
                                                        <td>${order.amount}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }

    }

    changeView = (e) => {
        e.preventDefault()
        this.setState({ view: e.target.id })
    }

    render() {
        const { user } = this.state

        return (
            <div>
                <Breadcrumb title={'Dashboard'} />


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <a className="popup-btn">
                                        my account
                                    </a>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                        <span className="filter-back">
                                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li><a id="account-info" onClick={this.changeView}>Account Info</a></li>
                                            {/* <li><a href="#">Address Book</a></li> */}
                                            <li><a id="my-orders" onClick={this.changeView}>My Orders</a></li>
                                            {/* <li><a href="#">My Wishlist</a></li>
                                            <li><a href="#">Newsletter</a></li>
                                            <li><a href="#">My Account</a></li>
                                            <li><a href="#">Change Password</a></li> */}
                                            <li className="last"><a onClick={this.logout}>Log Out</a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            {this.renderSwitch(this.state.view)}
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Dashboard