import * as types from '../constants/ActionTypes'


const filtersReducerDefaultState = {
    brand: ["Jockey Colours", "Saddle Cloths & Strapper Jackets", "Racing Extras", "Thoroughbred", "Miniature Jockey Colours", "Jockey Caps", "Harness Racing", "Horse Blinkers", "Presentation Sashes", "Presentation Rugs"],
    value: { min: 30, max: 950 },
    sortBy: "Newest"
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
    // console.log('Action Result');
    // console.log(action);
    switch (action.type) {
        case types.FILTER_BRAND:
            return {
                ...state,
                brand: action.brand
            };
        case types.FILTER_COLOR:
            return {
                ...state,
                color: action.color
            };
        case types.FILTER_PRICE:
            return {
                ...state,
                value: {min: action.value.value.min, max: action.value.value.max }
            };
        case types.SORT_BY:
            return {
                ...state,
                sortBy: action.sort_by
            };
        default:
            return state;
    }
}

export default filtersReducer;