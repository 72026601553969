import React, { Component } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import AuthService from '../../services/auth';
import jsPDF from 'jspdf';
import * as moment from 'moment'
import InjectedCheckoutForm from "./cardForm";
import LoadingOverlay from 'react-loading-overlay';

import Breadcrumb from "../common/breadcrumb";
import { removeFromWishlist, removeFromCart, clearCart } from '../../actions'
import { getCartTotal } from "../../services";

const url = 'https://mittys-api.bento.solutions/api/'
// const url = 'http://localhost:8080/api/'

class checkOut extends Component {

    constructor(props) {
        super(props)

        this.state = {
            payment: 'stripe',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            country: '',
            address: '',
            city: '',
            state: '',
            postcode: '',
            amount: this.props.total,
            ip: "110.33.122.75",
            cardNumber: "",
            cvv: "",
            expiryMonth: "",
            expiryYear: "",
            user: null,
            shippingDetails: false,
            shippingFirstName: '',
            shippingLastName: '',
            shippingPhone: '',
            shippingCountry: '',
            shippingAddress: '',
            shippingCity: '',
            shippingState: '',
            shippingPostcode: '',
            error: null,
            total: this.props.total,
            ausPost: false,
            expressPost: false,
            courierPost: false,
            postage: false,
            disableButton: false,
            loading: false

        }
        this.validator = new SimpleReactValidator();
        this.checkUser = this.checkUser.bind(this);
        this.checkUser();
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    async checkUser() {
        const user = await AuthService.getUser();
        if (user !== this.state.user) {
            this.setState({ user: user.user }, () => { });
        }
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    setStateFromCheckbox = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.setState(obj);
    }

    shippingPrice = (event) => {
        var checkboxes = document.getElementsByName('shipping-check')
        checkboxes.forEach((item) => {
            if (item !== event.target) item.checked = false
        })
        if (event.target.id == 'aus-post') {
            if (event.target.checked) {
                this.setState({ total: this.props.total + 15, amount: this.props.total + 15, ausPost: true, postage: true }, () => console.log(this.state))

            }
            else {
                this.setState({ total: this.props.total, amount: this.props.total, ausPost: false, postage: false }, () => console.log(this.state))
            }

        }
        else if (event.target.id == 'express-post') {
            if (event.target.checked) {
                this.setState({ total: this.props.total + 25, amount: this.props.total + 25, expressPost: true, postage: true }, () => console.log(this.state))

            }
            else {
                this.setState({ total: this.props.total, amount: this.props.total, expressPost: false, postage: false }, () => console.log(this.state))
            }
        }
        else if (event.target.id == 'courier-post') {
            if (event.target.checked) {
                this.setState({ total: this.props.total + 30, amount: this.props.total + 30, courierPost: true, postage: true }, () => console.log(this.state))

            }
            else {
                this.setState({ total: this.props.total, amount: this.props.total, courierPost: false, postage: false }, () => console.log(this.state))
            }
        }

    }

    checkhandle(value) {
        this.setState({
            payment: value
        })
    }

    payError = (error) => {
        this.setState({ disableButton: true, loading: true })
        let body = this.state
        body.products = this.props.cartItems
        let custom = body.products.find((prod) => prod.customDesign)
        if (custom) {
            body.pdf = this.customDesignPDF(custom, custom.price)
        }
        axios.post(url + 'payment/error', body)
            .then((payment) => {
            })
            .catch((err) => {
                this.setState({ error: true, disableButton: false, loading: false })
            })
    }
    prePay = () => {
        let body = this.state
        body.products = this.props.cartItems
        let custom = body.products.find((prod) => prod.customDesign)
        if (custom) {
            body.pdf = this.customDesignPDF(custom, custom.price)
        }
        axios.post(url + 'payment', body)
            .then((payment) => {
            })
            .catch((err) => {
            })
    }
    pay = () => {
        this.setState({ disableButton: true, loading: true })
        let body = this.state
        body.products = this.props.cartItems
        let custom = body.products.find((prod) => prod.customDesign)
        if (custom) {
            body.pdf = this.customDesignPDF(custom, custom.price)
        }
        axios.post(url + 'payment', body)
            .then((payment) => {
                this.setState({ error: null })
                const { cartItems, symbol } = this.props;
                const { address, postcode, city, state, firstName, lastName, shippingDetails, total, ausPost, expressPost, courierPost } = this.state
                this.props.clearCart()
                if (shippingDetails) {
                    const { shippingAddress, shippingPostcode, shippingCity, shippingState, shippingFirstName, shippingLastName, shippingCountry } = this.state
                    var shipping = { shippingAddress, shippingPostcode, shippingCity, shippingState, shippingFirstName, shippingLastName, shippingCountry }
                }
                else {
                    var shipping = { address, postcode, city, state, firstName, lastName }
                }
                this.setState({ loading: false })
                this.props.history.push({
                    pathname: '/order-success',
                    state: { payment: payment.data, items: cartItems, orderTotal: total, symbol: symbol, shipping: shipping, post: ausPost }
                })
            })
            .catch((err) => {
                this.setState({ error: true, disableButton: false, loading: false })
            })


    }
    customDesignPDF(product, price) {
        let mittysLogo = new Image()
        mittysLogo.src = '/assets/images/icon/logo.png'
        const date = moment().format('DD/MM/YYYY')
        const footer = "© Mittys 2018 - 2019"
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.setFontSize(20)
        pdf.text('Mittys Custom Design', 70, 25)
        pdf.setFontSize(10)
        pdf.text(date, 175, 12)
        pdf.setFontSize(12)
        pdf.text('Reference:  ######', 165, 20)
        pdf.setFontSize(14)
        if (price >= 210) {
            pdf.text('Jockey Colours (Cap + Shirt)', 100, 60)
            pdf.text('$210.00', 100, 70)
            pdf.setFontSize(10)
            pdf.text(`- Cap Colour: ${product.capColour}`, 100, 80)
            pdf.text(`- Cap Pattern: ${product.capPattern}, Pattern Colour: ${product.capPatternColour}`, 100, 90)
            pdf.text(`- Jacket Colour: ${product.jacketColour}`, 100, 100)
            pdf.text(`- Jacket Pattern: ${product.jacketPattern}, Pattern Colour: ${product.jacketPatternColour}`, 100, 110)
        }
        else if (price == 42.5) {
            pdf.text('Jockey Colours (Cap)', 100, 60)
            pdf.text('$42.50', 100, 70)
            pdf.setFontSize(10)
            pdf.text(`- Cap Colour: ${product.capColour}`, 100, 80)
            pdf.text(`- Cap Pattern: ${product.capPattern}, Pattern Colour: ${product.capPatternColour}`, 100, 90)
        }
        if (price === 125 || price === 335) {
            pdf.setFontSize(14)
            pdf.text('Blinkers', 100, 140)
            pdf.text('$125.00', 100, 150)
            pdf.setFontSize(10)
            pdf.text(`- Blinkers Colour: ${product.blinkersColour}`, 100, 160)
            pdf.text(`- Blinkers Pattern: ${product.blinkersPattern}, Pattern Colour: ${product.blinkersPatternColour}`, 100, 170)
        }
        pdf.addImage(mittysLogo, 'png', 10, 5)
        pdf.addImage(product.customDesign, 'png', 10, 30)
        pdf.setFontSize(8)
        pdf.text(footer, 92, pdf.internal.pageSize.height - 5)
        return pdf.output('datauristring')
    }

    updateParent = () => {
        this.forceUpdate()
    }

    render() {
        const { cartItems, symbol, total } = this.props;

        const onCancel = (data) => {
            console.log('The payment was cancelled!', data);
        }

        const onError = (err) => {
            console.log("Error!", err);
        }

        return (

            <LoadingOverlay
                active={this.state.loading}
                spinner
            >
                <div>

                    {/*SEO Support*/}
                    <Helmet>
                        <title>Mittys | Checkout</title>
                        <meta name="description" content="Mittys – Checkout" />
                    </Helmet>
                    {/*SEO Support End */}

                    <Breadcrumb title={'Checkout'} />

                    <section className="section-b-space">
                        <div className="container padding-cls">
                            <div className="checkout-page">
                                <div className="checkout-form">
                                    <form>
                                        <div className="checkout row">
                                            {!this.state.shippingDetails ?
                                                <div className="col-lg-6 col-sm-12 col-xs-12" >
                                                    <div className="checkout-title">
                                                        <h3>1. Billing Details</h3>
                                                    </div>
                                                    <div className="row check-out">
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">First Name</div>
                                                            <input type="text" name="firstName" value={this.state.firstName} onChange={this.setStateFromInput} />
                                                            {this.validator.message('first name', this.state.firstName, 'required|alpha')}
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Last Name</div>
                                                            <input type="text" name="lastName" value={this.state.lastName} onChange={this.setStateFromInput} />
                                                            {this.validator.message('last name', this.state.lastName, 'required|alpha')}
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Phone</div>
                                                            <input type="text" name="phone" value={this.state.phone} onChange={this.setStateFromInput} />
                                                            {/* {this.validator.message('Phone', this.state.phone, 'required|phone')} */}
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Email Address</div>
                                                            <input type="text" name="email" value={this.state.email} onChange={this.setStateFromInput} />
                                                            {this.validator.message('email', this.state.email, 'required|email')}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                            <div className="field-label">Country</div>
                                                            <select name="country" value={this.state.country} onChange={this.setStateFromInput}>
                                                                <option>Australia</option>
                                                            </select>
                                                            {/* {this.validator.message('country', this.state.country, 'required')} */}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                            <div className="field-label">Address</div>
                                                            <input type="text" name="address" value={this.state.address} onChange={this.setStateFromInput} placeholder="Street address" />
                                                            {this.validator.message('address', this.state.address, 'required|min:10|max:120')}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                            <div className="field-label">City/Suburb</div>
                                                            <input type="text" name="city" value={this.state.city} onChange={this.setStateFromInput} />
                                                            {this.validator.message('city', this.state.city, 'required|alpha_space')}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                            <div className="field-label">State</div>
                                                            <input type="text" name="state" value={this.state.state} onChange={this.setStateFromInput} />
                                                            {this.validator.message('state', this.state.state, 'required|alpha')}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                            <div className="field-label">Post Code</div>
                                                            <input type="text" name="postcode" value={this.state.spostcode} onChange={this.setStateFromInput} />
                                                            {this.validator.message('post code', this.state.postcode, 'required|integer')}
                                                        </div>
                                                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <input type="checkbox" name="shippingDetails" id="shippingDetails" checked={this.state.shippingDetails} onChange={this.setStateFromCheckbox} />
                                                        &ensp; <label htmlFor="shippingDetails">Shipping details not the same as billing details?</label>
                                                            {/* {this.validator.message('checkbox', this.state.shippingDetails, 'shipping-details')} */}
                                                        </div>
                                                    </div>
                                                </div>

                                                :
                                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                                    <div className="checkout-title">
                                                        <h3>2. Shipping Details</h3>
                                                    </div>
                                                    <div className="row check-out">
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">First Name</div>
                                                            <input type="text" name="shippingFirstName" value={this.state.shippingFirstName} onChange={this.setStateFromInput} />
                                                            {/* {this.validator.message('shippingFirstName', this.state.shippingFirstName, 'required|alpha')} */}
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Last Name</div>
                                                            <input type="text" name="shippingLastName" value={this.state.shippingLastName} onChange={this.setStateFromInput} />
                                                            {/* {this.validator.message('shippingLastName', this.state.shippingLastName, 'required|alpha')} */}
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                            <div className="field-label">Phone</div>
                                                            <input type="text" name="shippingPhone" value={this.state.shippingPhone} onChange={this.setStateFromInput} />
                                                            {/* {this.validator.message('shippingPhone', this.state.shippingPhone, 'required|shippingPhone')} */}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                            <div className="field-label">Country</div>
                                                            <select name="shippingCountry" value={this.state.shippingCountry} onChange={this.setStateFromInput}>
                                                                <option>Australia</option>
                                                            </select>
                                                            {/* {this.validator.message('shippingCountry', this.state.shippingCountry, 'required')} */}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                            <div className="field-label">Address</div>
                                                            <input type="text" name="shippingAddress" value={this.state.shippingAddress} onChange={this.setStateFromInput} placeholder="Street address" />
                                                            {/* {this.validator.message('shippingAddress', this.state.shippingAddress, 'required|min:20|max:120')} */}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                            <div className="field-label">City/Suburb</div>
                                                            <input type="text" name="shippingCity" value={this.state.shippingCity} onChange={this.setStateFromInput} />
                                                            {/* {this.validator.message('shippingCity', this.state.shippingCity, 'required|alpha')} */}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                            <div className="field-label">State</div>
                                                            <input type="text" name="shippingState" value={this.state.shippingState} onChange={this.setStateFromInput} />
                                                            {/* {this.validator.message('shippingState', this.state.shippingState, 'required|alpha')} */}
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                            <div className="field-label">Post Code</div>
                                                            <input type="text" name="shippingPostcode" value={this.state.shippingPostcode} onChange={this.setStateFromInput} />
                                                            {/* {this.validator.message('shippingPostcode', this.state.shippingPostcode, 'required|integer')} */}
                                                        </div>
                                                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <input type="checkbox" name="shippingDetails" id="shippingDetails" checked={this.state.shippingDetails} onChange={this.setStateFromCheckbox} />
                                                        &ensp; <label htmlFor="shippingDetails">Shipping details not the same as billing details?</label>
                                                            {/* {this.validator.message('checkbox', this.state.shippingDetails, 'shippingDetails')} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-lg-6 col-sm-12 col-xs-12">
                                                <div className="checkout-details">
                                                    <div className="order-box">
                                                        <div className="title-box">
                                                            <div>Product <span> Total</span></div>
                                                        </div>
                                                        <ul className="qty">
                                                            {cartItems.map((item, index) => {
                                                                return <li key={index}>{item.name} × {item.qty} <span>{symbol} {item.sum}</span></li>
                                                            })
                                                            }
                                                        </ul>
                                                        <ul className="sub-total">
                                                            <li>Subtotal <span className="count">{symbol}{total}</span></li>
                                                            <li>Shipping <div className="shipping">
                                                                {/* <div className="shopping-option">
                                                                <input type="checkbox" name="shipping-check" id="aus-post" onChange={this.shippingPrice} />
                                                                <label htmlFor="aus-post" >Australia Post $15</label>
                                                                {this.validator.message('shipping', this.state.postage, 'required|alpha')}
                                                            </div> */}
                                                                <div className="shopping-option">
                                                                    <input type="checkbox" name="shipping-check" id="express-post" onChange={this.shippingPrice} />
                                                                    <label htmlFor="express-post">Express Post $25</label>
                                                                    {this.validator.message('shipping', this.state.postage, 'required|alpha')}
                                                                </div>
                                                                {/* <div className="shopping-option">
                                                                <input type="checkbox" name="shipping-check" id="courier-post" onChange={this.shippingPrice} />
                                                                <label htmlFor="courier-post">Courier $30</label>
                                                                {this.validator.message('shipping', this.state.postage, 'required|alpha')}
                                                            </div> */}
                                                            </div>
                                                            </li>
                                                        </ul>

                                                        <ul className="total">
                                                            <li>Total <span className="count">{symbol}{this.state.total}</span></li>
                                                        </ul>
                                                    </div>

                                                    <div className="payment-box">
                                                        {/* {(total !== 0) ?
                                                        <div className="text-right">
                                                            {(this.state.payment === 'stripe') ? <button type="button" className="btn-solid btn" onClick={() => this.StripeClick()} >Place Order</button> :
                                                                <PaypalExpressBtn env={'sandbox'} client={client} currency={'USD'} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />}
                                                        </div>
                                                        : ''} */}

                                                    </div>
                                                </div>
                                                <div className="row section-t-space">
                                                    <div className="col-lg-12">
                                                        <div className="stripe-section text-center">
                                                            <h5>Card Details</h5>
                                                            <InjectedCheckoutForm details={this.state} validator={this.validator} update={this.updateParent} pay={this.pay} payError={this.payError} prePay={this.prePay} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </LoadingOverlay>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    { removeFromWishlist, clearCart }
)(checkOut)