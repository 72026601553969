// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:5e560442-fadf-4622-ba4b-4d606255ac55",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_5UN8p38Ul",
    "aws_user_pools_web_client_id": "3kr2ik6mibdatgpo1j2d1ulgph",
    "oauth": {},
    "aws_user_files_s3_bucket": "mittys-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
