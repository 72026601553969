import { Auth } from 'aws-amplify';
import axios from 'axios';

const url = 'https://mittys-api.bento.solutions/api/'
// const url = 'http://localhost:8080/api/'

const AuthService = {
  signIn: (username, password) => {
    console.log(username)
    return new Promise((resolve, reject) => {
      Auth.signIn({ username, password })
        .then((result) => {
          axios.post(url + 'user/find', { email: username })
            .then(user => resolve({ ok: true, error: null }))
            .catch(err => resolve({ ok: false, error: err }))
        })
        .catch(err => resolve({ ok: false, error: err }))
    })
  },

  signUp: (username, password, email, fname, lname) => {
    return new Promise((resolve, reject) => {
      Auth.signUp({ username, password, attributes: { email } })
        .then((result) => {
          let user = { username, fname, lname, email: username }
          axios.post(url + 'user/save', user)
            .then(user => resolve({ ok: true, error: null }))
            .catch(err => resolve({ ok: false, error: err }))
        })
        .catch(err => resolve({ ok: false, error: err }))
    })
  },

  confirm: (username, code) => {
    return Auth.confirmSignUp(username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    })
  },

  signOut: () => {
    return Auth.signOut()
  },

  getUser: () => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((result) => {
          axios.post(url + 'user/find', { email: result.attributes.email })
            .then(user => {resolve({ ok: true, error: null, user: user.data })})
            .catch(err => resolve({ ok: false, error: err }))
        })
        .catch(() => console.log("Not signed in"))

    })
  },

  getOrders: (user) => {
    return new Promise((resolve, reject) => {
      axios.post(url + 'order/list', user)
        .then(orders =>  resolve({ ok: true, error: null, orders }))
        .catch(err => resolve({ ok: false, error: err }))
    })

  }

}

export default AuthService