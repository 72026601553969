import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// Custom Components
import HeaderOne from './common/headers/header-one';


import FooterOne from "./common/footers/footer-one";
// Live key pk_live_MzHGu3zbYYXjWzR3lHmikOyo00torTyYuC
// Test key pk_test_0UOOm5EWU3AuPo2flIKyCFrN00NImLGGTf
const stripePromise = loadStripe('pk_live_MzHGu3zbYYXjWzR3lHmikOyo00torTyYuC');


class App extends Component {

    render() {

        return (
            <Elements stripe={stripePromise}>
                <div>
                    <HeaderOne history={this.props.history} />
                    {this.props.children}
                    <FooterOne />
                    <ToastContainer />

                    {/* <ThemeSettings /> */}
                </div>
            </Elements>

        );
    }
}

export default withTranslate(App);
