import React, { Component } from 'react';
import Breadcrumb from "../common/breadcrumb";

class Faq extends Component {

    constructor(props) {
        super(props)
    }

    render() {


        return (
            <div>
                <Breadcrumb title={'Faq'} />

                <section className="faq-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    I am an overseas customer, do the same prices online apply to me?
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                            data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>If you are just browsing, the prices online are including GST (10% Australian Tax) which would not apply to any overseas customers.
                                                As soon as you log in with your username and password, the site recognizes your country of origin and the prices that will appear will be less this 10% tax are what you will pay
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                    Will I have to pay any taxes and duties for my order?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                            data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>Orders shipped via Express will not incur duties and taxes at delivery. These fees are included in your online shipping total.
                                                International orders, shipped via Standard or Economy, can incur duties and taxes. Duties and taxes are determined by the customs agency within the destination country. Although charges are not always incurred, assessment of duties and taxes will be based on the value of the order and the Tax-free threshold, if it exists, for goods imported into the destination country.
                                                Payment of any duties and taxes is the responsibility of the recipient, and these fees are collected at the time of delivery.
                                                More information can be obtained by contacting your local customs office.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">
                                                    Refund & Return Policy
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                            data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>The customer must contact Mittys at info@mittys.com.au within 15 days after date of purchase (proof of purchase required). After the 15 days Mittys has the rights to refuse the return.</p>
                                                <br />
                                                <p>All returns for exchange and/or refund to the customer’s account are subject to review by Mittys to verify the product has not been damaged or used. If it is found that the product has been damaged or used, an exchange or refund will not be permitted.</p>
                                                <br />
                                                <p>In the event of a product being faulty, it must be returned to Mittys with proof of purchase. The item will be reviewed, if it is deemed to be manufacturing fault, the item will be replaced or repaired to the satisfaction of the customer. If the damage is deemed to be caused by customer, the item will be returned to the customer or on request from the customer, repaired at the appropriate charge.</p>
                                                <br />
                                                <p>You can choose a refund or exchange if an item has a major problem.  This is when the item:</p>
                                                <ul className="list-group">
                                                    <li className="list-group-item">has a problem that would have stopped someone from buying the item if they had known about it</li>
                                                    <li className="list-group-item">is unsafe</li>
                                                    <li className="list-group-item">is significantly different from the sample or description</li>
                                                    <li className="list-group-item">doesn’t do what we said it would, or what you asked for and can’t be easily fixed.</li>
                                                </ul>
                                                <br />
                                                <p>Alternatively, you can choose to keep the item and we will compensate you for any drop in value.</p>
                                                <br />
                                                <p>Personalized or made to order items cannot be returned.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFour"
                                                    aria-expanded="false" aria-controls="collapseFour">
                                                    Item Exchange
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour"
                                            data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>Items ordered incorrectly where the size is wrong, wrong colour, wrong brand etc can be returned for exchange.  Please post these back and include in the parcel a self addressed pre-paid return parcel so we can send you the correct item straight back.</p>
                                                <br />
                                                <p>We recommend when sending back returns you utilize registered or express post Mittys does not take responsibility for items not received if a customer attempts to send an item via non registered post. Please allow 10 days from the day you return your product for your account to be credited or your exchange product to be redelivered.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFive">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFive"
                                                    aria-expanded="false" aria-controls="collapseFive">
                                                    Shipping Policy
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                                            data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>Products returned to Mittys by the shipper for reasons of refusal, the goods will be credited however the postage charges will not.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingSix">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseSix"
                                                    aria-expanded="false" aria-controls="collapseSix">
                                                    Disclaimer
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseSix" className="collapse" aria-labelledby="headingSix"
                                            data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>International orders are subject to import taxes, duties and customs taxes, which are applied once your shipment reaches the country of destination.</p>
                                                <p>All taxes, duties and customs fees are the responsibility of the recipient of the package. Please do not request that we falsify your invoices or suppress information.</p>
                                                <br/>
                                                <p>Refused or returned shipments in which Mittys incurs additional shipping fees will be assessed on the return credit. Should you refuse to pay all applicable import taxes, customs taxes, and duties upon delivery of your shipment and all future attempts to collect said fees result in an unsatisfactory result, Mittys reserves the right to charge you 100% of any fees thereafter imposed.</p>
                                                <br/>
                                                <p>Any price or special offer on our website is subject to change without notice. The prices displayed on the site are quoted in Australian Dollars.</p>
                                                <br/>
                                                <p>Your order will be charged in Australian dollars, the prices can be converted to another choice of currency to give you an approximate guide as to the price.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Faq