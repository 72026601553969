import React, { Component, Fragment } from 'react';
import {Helmet} from 'react-helmet';
import HeaderOne from "../../common/headers/header-one";
import FooterOne from "../../common/footers/footer-one";
import Faq from '../../pages/faq';

class Questions extends Component {

  componentDidMount() {
    setTimeout(function() {
        document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  render() {
    return (
      <Fragment>
        <HeaderOne history={this.props.history} />
        <Faq />
        <FooterOne /> 
      </Fragment>
    )
  }

}

export default Questions