import React, { Component, Fragment } from 'react';
import Dropzone from '../dropzone/dropzone';
import Progress from '../progress/progress';
import SimpleReactValidator from 'simple-react-validator';
import './upload.css'
import * as axios from 'axios'



class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      name: '',
      message: '',
      buttonDisabled: false
    };
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.validator = new SimpleReactValidator();
  }
  onFilesAdded(files) {
    this.setState(prevState => ({
      files: files
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest());
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest() {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      if (this.state.files.length > 0) {
        const file = this.state.files[0]
        formData.append("file", file, file.name);
      }
      formData.append("name", this.state.name)
      formData.append("message", this.state.message)
      const response = await axios({
        method: 'post',
        url: 'https://mittys-api.bento.solutions/api/post/upload',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      resolve(response)
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="/assets/images/check-circle-outline.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }
  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length <= 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({ buttonDisabled: true })
    if (this.validator.allValid()) {
      await this.sendRequest()
      this.setState({ buttonDisabled: false })
      this.props.history.push('/horse-tales')
    }
    else {
      // this.setState({disableButton: false})
      this.validator.showMessages()
      this.forceUpdate();
      this.setState({ buttonDisabled: false })
    }
  }

  handleChange = (event) => {
    let obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  render() {
    return (
      <Fragment>
        <div className="TalesApp">
          <div className="TalesHeader">
            <h1>ADD TALE</h1>
            <hr id="home-hr" /></div>
          <div className="TalesCard">
            <div className="Upload">
              <span className="Title">Add a Photo</span>
              <div className="Content">
                <div>
                  <Dropzone
                    onFilesAdded={this.onFilesAdded}
                    disabled={this.state.uploading || this.state.successfullUploaded}
                  />
                </div>
                <div className="Files">
                  {this.state.files.map(file => {
                    return (
                      <div key={file.name} className="Row">
                        <span className="Filename">{file.name}</span>

                        {this.renderProgress(file)}
                      </div>
                    );
                  })}
                </div>
              </div>
              <form className="uploadForm theme-form" onSubmit={this.handleSubmit} >
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label className="field-label">Your Name</label>
                      <input className="name-input" name="name" type="text" placeholder="Name to display with your post..." onChange={this.handleChange}></input>
                      {this.validator.message('name', this.state.name, 'required|alpha_num_dash_space')}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label className="field-label">Your Message</label>
                      <textarea placeholder="Your message..." name="message" onChange={this.handleChange}></textarea>
                      {this.validator.message('message', this.state.message, 'required')}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <input className="btn btn-solid" type="submit" value="Post" disabled={this.state.buttonDisabled} />
                  </div>
                </div>
              </form>
              {/* <div className="Actions">{this.renderActions()}</div> */}
            </div>

          </div>
        </div>

      </Fragment>
    )
  }
}

export default UploadForm;

