import React, { Component } from 'react';
// import { Auth } from 'aws-amplify';
import  AuthService  from '../../services/auth'

import Breadcrumb from "../common/breadcrumb";

class Register extends Component {

    constructor(props) {
        super(props)
        this.state = {
            signup: true,
            confirm: false,
            error: false
        }

    }

    input = (e) => {
        console.log(e.target.id)
        if (e.target.id === 'email') this.setState({ username: e.target.value, email: e.target.value })
        if (e.target.id === 'review') this.setState({ password: e.target.value })
        if (e.target.id === 'verification') this.setState({ code: e.target.value })
        if (e.target.id === 'fname') this.setState({fname: e.target.value})
        if (e.target.id === 'lname') this.setState({lname: e.target.value})
    }

    signUp = (e) => {
        e.preventDefault()
        const { username, password, email, fname, lname } = this.state
        AuthService.signUp(username, password, email, fname, lname)
            .then((data) =>{
                console.log(data)
                 if (data.ok)this.setState({ signup: false, confirm: true, error: false })
                 else this.setState({error: data.error})
                })
            .catch(err => this.setState({error: err}));
    }

    confirmSignUp = (e) => {
        e.preventDefault()
        const { username, code } = this.state
        AuthService.confirm(username, code)
            .then(data => this.props.history.push('/login'))
            .catch(err => this.setState({error: err}));
    }



    render() {


        return (
            <div>
                <Breadcrumb title={'create account'} />
                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            {this.state.signup  ?
                                <div className="col-lg-12 signup">
                                    <h3>create account</h3>
                                    <div className="theme-card">
                                        <form className="theme-form">
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label htmlFor="email">First Name</label>
                                                    <input onChange={this.input} type="text" className="form-control" id="fname"
                                                        placeholder="First Name" required="" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="review">Last Name</label>
                                                    <input onChange={this.input} type="text" className="form-control" id="lname"
                                                        placeholder="Last Name" required="required" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label htmlFor="email">email</label>
                                                    <input onChange={this.input} type="text" className="form-control" id="email"
                                                        placeholder="Email" required="" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="review">Password</label>
                                                    <input onChange={this.input} type="password" className="form-control" id="review"
                                                        placeholder="Enter your password" required="" />
                                                </div>
                                                <a href="#" onClick={this.signUp} className="btn btn-solid">create Account</a>
                                            </div>
                                            {this.state.error ? 
                                                <div className ="form-row text-center">
                                                    <p style={{width: "100%", color: "red"}}>{this.state.error.message}</p>
                                                </div>
                                                : 
                                                null
                                            }
                                        </form>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {this.state.confirm  ?
                                <div className="col-lg-12 signup">
                                    <h3>Verify your Account</h3>
                                    <div className="theme-card">
                                        <form className="theme-form">
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <label htmlFor="email">Verification Code</label>
                                                    <input onChange={this.input} type="text" className="form-control" id="verification"
                                                        placeholder="Verification Code..." required="" />
                                                </div>
                                                <a href="#" onClick={this.confirmSignUp} className="btn btn-solid">Verify</a>
                                            </div>
                                            {this.state.error ? 
                                                <div className ="form-row text-center">
                                                    <p style={{width: "100%", color: "red"}}>{this.state.error.message}</p>
                                                </div>
                                                : 
                                                null
                                            }
                                        </form>
                                    </div>
                                </div>
                                :
                                null

                            }

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Register