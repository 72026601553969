import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SlideUpDown } from "../../../services/script"

class FooterOne extends Component {

    componentDidMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function (elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }


    render() {

        return (
            <footer className="footer-light">
                <div className="light-layout">
                    <div className="container">
                        <section className="small-section border-section border-top-0">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="subscribe">
                                        <div>
                                            <h4>KNOW IT ALL FIRST!</h4>
                                            <p>Never Miss Anything From Mittys By Signing Up To Our Newsletter. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <form className="form-inline subscribe-form">
                                        <div className="form-group mx-sm-3">
                                            <input type="text" className="form-control" id="exampleFormControlInput1"
                                                placeholder="Enter your email" />
                                        </div>
                                        <button type="submit" className="btn btn-solid">subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row footer-theme partition-f">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-title footer-mobile-title">
                                    <h4>about</h4>
                                </div>
                                <div className="footer-contant">
                                    <div className="footer-logo">
                                        <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/logo.png`} alt="" />
                                        </Link>
                                    </div>
                                    <p>Contact Mittys today, you’ll be glad you did and we look forward to a long and successful partnership.</p>
                                    <div className="footer-social">
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/MittysRacing/" target="_blank"> <i className="fa fa-facebook" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <Link to={'https://linkedin.com/'} ><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://instagram.com'}><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col offset-xl-1">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>my account</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul>
                                            <li><Link to={`${process.env.PUBLIC_URL}/`} >Home</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/custom`} >Design Tool</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/products`} >Product Range</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/login`} >My Account</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>Information</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul>
                                            {/* <li><a href="#">shipping & return</a></li> */}
                                            <li><Link to={`${process.env.PUBLIC_URL}/questions`} >FAQ</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/links`} >Useful Info</Link></li>
                                            {/* <li><a href="#">contacts</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>store information</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul className="contact-list">
                                            <li><i className="fa fa-map-marker"></i>Mittys
                                            </li>
                                            <li><i className="fa fa-phone"></i>Call Us:  (03) 8413 6611</li>
                                            <li><i className="fa fa-envelope-o"></i>Email Us: <a
                                                href="#" className="info-link">info@mittys.com.au</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="sub-footer ">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="footer-end">
                                    <p><i className="fa fa-copyright" aria-hidden="true"></i> 2020 Mittys</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default FooterOne;