import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

// Import custom components

import SpecialProducts from "../common/products";
import HeaderOne from "../../common/headers/header-one";
import FooterOne from "../../common/footers/footer-one";


class Home extends Component {


    componentDidMount() {
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Mittys | Jockey Colours</title>
                    <meta name="description" content="Mittys – Racing and Jockey Colours" />
                </Helmet>
                {/*Home Slider*/}
                <HeaderOne history={this.props.history} />
                <section className="p-0">
                    <Slider className="slide-1 home-slider">
                        <div>
                            <div className="home home1 text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                <div>
                                                    <h2 className="home-banner-h2 d-sm-block d-none design-your-colours">DESIGN YOUR COLOURS AND BLINKERS ONLINE</h2>
                                                    <h2 className="home-banner-h2 d-sm-none text-left responsive-header" >DESIGN YOUR</h2>
                                                    <h2 className="home-banner-h2 d-sm-none text-left responsive-header" >COLOURS AND</h2>
                                                    <h2 className="home-banner-h2 d-sm-none text-left responsive-header" >BLINKERS ONLINE</h2>
                                                    <Link to={`${process.env.PUBLIC_URL}/custom`} className="btn btn-solid">design now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                            <div className="home home2 text-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="slider-contain">
                                                <div>
                                                    <h2 className="home-banner-h2">DESIGN YOUR COLOURS AND BLINKERS ONLINE</h2>
                                                    <Link to={`${process.env.PUBLIC_URL}/custom`} className="btn btn-solid">design now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </Slider>
                </section>
                <section className="text-center main-page-content mittys-home">
                    <h3 id="home-title">WELCOME TO MITTYS</h3>
                    <hr id="home-hr" />
                    <div className="panel">
                        <h2>LIKE YOU, MITTYS IS TOTALLY COMMITTED TO SUCCESS!</h2>
                        <p>Our racing colours lead in the field in technologically superior design and aesthetics to give you the winning edge in a highly competitive sport.</p>
                        <p>Racing clubs the world over are now discovering the benefits of partnering with Mittys and we continue to be told that our products, professionalism and service are second to none. That’s winning form.</p>
                    </div>
                    <div className="panel">
                        <div className="ribbon-container">
                            <img className="ribbon-img" src="/assets/images/jockey/final-ribbon.png" ></img>
                        </div>
                        <h2>MITTYS UNDERSTANDS WINNING – IT’S IN THE BLOODLINE!</h2>
                        <p>Our products and service continue to evolve in line with ever-changing technologies and client needs, and we constantly review our business to ensure our service level remains exceptional. That’s why we confidently offer you a 100% money back guarantee on all our products.</p>
                        <p>So come on, put us to the test. We’re confident we’ll pass with flying colours.</p>
                    </div>
                </section>
                <SpecialProducts />
                <section className="evans-ad">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-4 evans-link">
                                <a href="https://evanevans.com.au/" target="_blank">
                                    <img src="https://cdn.yworld.com.au/photo/logoblue@EvansEvans-image-300x0-1-f2b.png" />
                                    <br />
                                    <strong>CONTACT US NOW</strong>
                                    <br />
                                    <strong>1800 990 989</strong>
                                </a>
                            </div>
                            <div className="col-md-8 col-sm-8">
                                <h2 className="blue-evans">MAKING YOUR JOB EASY - EVERY TIME!</h2>
                                <p>Evan Evans is at the forefront of the flag, banner and signage industry. We are one of Australia's leading largedigital printers and pride ourselves on producing the highest quality flag, banner, signage and event</p>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterOne />
            </div>
        )


    }
}

export default Home;