import React, {Component} from 'react';
import  AuthService  from '../../services/auth';
import { Link } from 'react-router-dom';

import Breadcrumb from "../common/breadcrumb";

class Login extends Component {

    constructor (props) {
        super (props)
        this.state = {
            error: false,
            
        }

    }

    input = (e) => {
        this.setState({[e.target.id]: e.target.value})
    }

    signIn = (e) => {
        e.preventDefault()
        const { username, password } = this.state
        AuthService.signIn(username, password)
            .then(user => {
                if (user.ok) {
                    this.setState({error: false})
                    this.props.history.push('/')
                }
                else {
                    this.setState({error: user.error})
                }
            })
            .catch(err => {
                this.setState({error: err})
            })
    }

    render (){
        return (
            <div>
                <Breadcrumb title={'Login'}/>
                
                
                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Login</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input onChange={this.input} type="text" className="form-control" id="username" placeholder="Email"
                                                   required="" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input onChange={this.input} type="password" className="form-control" id="password"
                                                   placeholder="Enter your password" required="" />
                                        </div>
                                        <a href="#" onClick={this.signIn} className="btn btn-solid">Login</a>
                                        {this.state.error ? 
                                            <div className ="form-row text-center">
                                                <p style={{width: "100%", color: "red"}}>{this.state.error.message}</p>
                                            </div>
                                            : 
                                            null
                                        }
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create AN Account</h6>
                                    <p>Sign up for a free account at our store. Registration is quick and easy. It
                                        allows you to be able to order from our shop. To start shopping click
                                        register.</p>
                                    {/* <a href="/register" className="btn btn-solid">Create an Account</a> */}
                                    <Link className="btn btn-solid" to={`${process.env.PUBLIC_URL}/register`} >Create an Account</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Login