import React, { Component } from "react";
import './post.css'
import axios from 'axios';
import * as moment from 'moment'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class Post extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageUrl: ''
    }
  }
  displayTime = (date) => {
    return moment(date).format('DD/MM/YY hh:mma')
  }
  removePost = async () => {
    const { post, getPosts} = this.props
    const url = 'https://mittys-api.bento.solutions/api/'
    // const url = 'http://localhost:8080/api/'
 
    const options = {
      title: 'Remove Post',
      message: 'Are your sure you want to remove this post?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await axios.post(url + 'post/remove', { _id: post._id })
            getPosts()
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    };
     
    confirmAlert(options);
  }

  render() {
    const post = this.props.post
    const user = this.props.user

    return <article className="Post" ref="Post">
      <header>
        <div className="Post-user">
          <div className="Post-user-avatar">
            <img src="/assets/images/icon/logo.png" alt={post.name} />
          </div>
          <div className="Post-user-nickname">
            <span>{post.name}</span>
          </div>
          {
            user && user.admin ?
              <button className="btn btn-solid" onClick={this.removePost}>Remove</button>
              :
              null
          }
        </div>
      </header>
      {
        post.image ?
          <div className="Post-image">
            <div className="Post-image-bg">
              <img alt="Icon Living" src={"https://mittys-production.s3-ap-southeast-2.amazonaws.com/" + post.image.key} />
            </div>
          </div>
          :
          null
      }

      <div className="Post-created">
        <span><strong>{this.displayTime(post.created)}</strong></span>
      </div>
      <div className="Post-caption">
        <span>{post.message}</span>
      </div>
    </article>;
  }
}
export default Post;